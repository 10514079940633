<template>
  <v-container fill-height fluid class="primary custom-container-height">
    <v-col class="custom-height" xs="12" sm="12" md="12">
      <v-row wrap>
        <v-col xs="10" offset-xs="1" sm="10" offset-sm="1" md="8" offset-md="2">
          <div class="card-style rounded-card">
            <v-card-title class="title-style">
              <h4>Set Your New Password</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-4">
              <v-form>
                <v-text-field ref="resetPassword" v-model="newPassword" type="password" label="Enter New Password"
                  required dense outlined :rules="passwordRules">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="resetPassword">Save</v-btn>
            </v-card-actions>
          </div>
          <p class="text-center custom-footer">
            © {{ new Date().getFullYear() }} All rights reserved by GoDial
          </p>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      newPassword: "",
      visible: false,
      loaderDialog: false,
      passwordRules: [
        (v) =>
          /^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(v) ||
          "Minimum eight characters, at least one letter and one number.",
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
  },
  watch: {},
  created() { },
  methods: {
    resetPassword(e) {
      const self = this;

      if (this.newPassword == "") {
        this.$swal({
          type: "error",
          text: "Please provide a password",
        });
        return;
      }

      if (!/^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(self.newPassword)) {
        //  this.loader = false
        return this.$swal({
          type: "warning",
          text: "Minimum eight characters, at least one letter and one number.",
        });
      }

      self.loaderDialog = true;

      self.$http
        .post(
          `${this.ENDPOINT}/accounts/reset-password?access_token=${self.$route.query.token}`,
          { newPassword: self.newPassword }
        )
        .then(
          (response) => {
            self.loaderDialog = false;
            self.$router.replace({ path: "/" });
          },
          (error) => {
            self.loaderDialog = false;
          }
        );
    },
  },
};
</script>

<style>
/* .v-card {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(0, 0, 0, 0),
    0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  border-radius: 12px !important;
} */

.custom-height {
  min-height: 100%;
}

.custom-container-height {
  min-height: 100vh;
  background-image: linear-gradient(to right, #37cf37, #37cf37);
}

.custom-footer {
  color: white;
  margin-top: 2rem;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}

.card-style {
  background-color: white;
}

.rounded-card {
  border-radius: 12px;
}

.title-style {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
</style>
